import { Lead } from "../Lead/Lead.js";
var lead = new Lead();
window.lead = lead;

$(function() {
	$(".header__menu-link").click(function() {
		$(".header__menu-link").removeClass("active");         
		$(this).toggleClass("active");
	})
});

$(function() {
	$(".swiper-pagination-bullet").click(function() {
		$(".swiper-pagination-bullet").removeClass("active");         
		$(this).toggleClass("active");
	})
});

// $(function() {
// 	$(".portfolio__item").click(function() {
// 		$(".portfolio__item").removeClass("active");         
// 		$(this).toggleClass("active");
// 	})
// });

$(document).ready(function(){
	$('.portfolio__item-icon').on('click', function () {
		$(this).next().toggleClass('active');
	});
});

$('.product-card__order--1').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--1').addClass('active');
});

$('.product-card__order--2').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--2').addClass('active');
});

$('.product-card__order--3').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--3').addClass('active');
});


const headerHeight = $('.header').outerHeight();
$('a[href^="#"]').click(function() {
	let elementClick = $(this).attr("href").replace(/#/, '');
	if (elementClick && $('a[name=' + elementClick + ']').length) {
		let destination = $('a[name=' + elementClick + ']').offset().top;
		$('html, body').animate({ scrollTop: destination - headerHeight }, 200);
		return false;
	}
});

$(function() {
	let header = $('.header');
	let hederHeight = header.height(); // вычисляем высоту шапки
	
	$('.main').css({
		'paddingTop': hederHeight+'px' // делаем отступ у body, равный высоте шапки
	});
});


document.querySelector('.header__burger').addEventListener('click', () => {
	document.querySelector('.header__burger').classList.toggle('closed');
	document.querySelector('.header__nav').classList.toggle('active');
});
